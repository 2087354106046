<template>
  <div>
    <b-navbar type="dark" variant="info">
      <b-navbar-brand>Contact moments</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-nav-item right>
          <b-button @click="add_row" size="sm" v-if="parent_item.id"><b-icon-plus></b-icon-plus></b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-table striped hover selectable select-mode="single" no-select-on-click
             :items="contact_moments" id="contact_moment_table"
             :busy="is_busy" primary-key="id" :fields="fields"
             @row-clicked="row_clicked" ref="contact_moment_table">
      <template #cell(status)="data">
        <b-form-select v-model="data.item.status" v-bind:disabled="!data.rowSelected"
                       @change="row_changed(data.item)" :options="status_options">
        </b-form-select>
      </template>
      <template #cell(date)="data">
        <b-form-datepicker v-model="data.item.date" v-bind:disabled="!data.rowSelected"
                           :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                           @input="row_changed(data.item)" >
        </b-form-datepicker>
      </template>
      <template #cell(notes)="data">
        <b-form-textarea v-model="data.item.notes" v-bind:disabled="!data.rowSelected"
                         rows="5"
                         @update="row_changed(data.item)" debounce="1000">
        </b-form-textarea>
      </template>
      <template #cell(edit)="data">
        <b-button v-if="!data.rowSelected" @click="row_clicked(data.item, data.index)" size="sm">
          <b-icon-pencil-square></b-icon-pencil-square>
        </b-button>
        <b-button v-if="data.rowSelected" @click="save_row" size="sm">
          <b-icon-check-square></b-icon-check-square>
        </b-button>
      </template>
      <template #cell(delete)="data">
        <b-button @click="delete_contact_moment(data)" size="sm">
          <b-icon-trash></b-icon-trash>
        </b-button>
      </template>
    </b-table>
    <b-modal ref="confirm_delete_modal" title="Confirm deletion" v-bind:busy="delete_busy">
      <p>Contact moment <span v-if="contact_moment_to_delete">'{{ contact_moment_to_delete.status }}' </span>
        <span v-if="contact_moment_to_delete && contact_moment_to_delete.status"> from {{ contact_moment_to_delete.status }}</span>
        <span v-if="contact_moment_to_delete && contact_moment_to_delete.notes"> to {{ contact_moment_to_delete.notes }}</span>.
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" v-bind:disabled="delete_busy" @click="delete_confirmed">
          Delete
        </b-button>
        <b-button size="sm" variant="success" v-bind:disabled="delete_busy" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContactMoment',
  props: {
    'parent_item': Object,
    'parent_endpoint': String,
  },
  data: function() {
    return {
      contact_moments: [],
      fields: [
        { key: 'status'},
        { key: 'date'},
        { key: 'notes'},
        { key: 'edit', label: ''},
        { key: 'delete', label: ''},
      ],
      status_options: [
        'planned',
        'performed',
        'cancelled',
      ],
      is_busy: false,
      contact_moment_to_delete: null,
      delete_busy: false,
    }
  },
  created: function() {
    if(this.parent_item.id !== null) {
      this.load_contact_moments();
    }
  },
  methods: {
    'load_contact_moments': function() {
      this.is_busy = true;
      axios.get(this.$store.getters.api_url([this.parent_endpoint, this.parent_item.id, 'contact_moments']), {
        headers: this.$store.getters.auth_headers(),
        withCredentials: true
      })
          .then(response => {
            this.contact_moments = response.data;
            this.is_busy = false;
          }).catch(error => {
            console.log(error);
            this.is_busy = false;
          });
    },
    'row_clicked': function(record, index) {
      this.$refs.contact_moment_table.selectRow(index);
    },
    'save_row': function() {
      this.$refs.contact_moment_table.clearSelected();
    },
    'row_changed': function(item) {
      console.log('row changed');
      let verb = axios.put;
      let endpoint = [this.parent_endpoint, this.parent_item.id, 'contact_moments', item.id];
      if(item.id == null) {
        // new item, POST
        verb = axios.post;
        endpoint = [this.parent_endpoint, this.parent_item.id, 'contact_moments'];
      }
      verb(this.$store.getters.api_url(endpoint), item, {
        headers: this.$store.getters.auth_headers(),
        withCredentials: true
      })
          .then(response => {
            let update_item = this.contact_moments.find(x => x.id == item.id);
            Object.assign(update_item, response.data);
          })
          .catch(error => {
            console.log(error);
          });
    },
    'add_row': function() {
      if(this.contact_moments.every(x => x.id != null)) {
        this.contact_moments.unshift({
          id: null,
          status: 'planned',
          date: null,
          notes: ''
        });
        const component = this;
        setTimeout(function () {
          // TODO: HACK! poor man's yield, but the table needs to re-render before being able to select
          component.$refs.contact_moment_table.clearSelected();
          component.$refs.contact_moment_table.selectRow(0);
        }, 0);
      }
    },
    'delete_contact_moment': function(data) {
      this.contact_moment_to_delete = data.item;
      this.$refs.confirm_delete_modal.show();
    },
    'delete_confirmed': function() {
      this.delete_busy = true;
      const component = this;
      const endpoint = [this.parent_endpoint, this.parent_item.id,
        'contact_moments', this.contact_moment_to_delete.id];
      axios.delete(this.$store.getters.api_url(endpoint), {
        headers: this.$store.getters.auth_headers(),
        withCredentials: true
      })
          .then(function() {
            component.$refs.confirm_delete_modal.hide();
            component.delete_busy = false;
            component.contact_moments = component.contact_moments.filter(x => x.id != component.contact_moment_to_delete.id);
            component.contact_moment_to_delete = null;
          })
          .catch(error => {
            component.$refs.confirm_delete_modal.hide();
            component.delete_busy = false;
            component.contact_moment_to_delete = null;
            console.log(error);
          });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
