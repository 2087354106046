<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand>SonoCRM</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">Dashboard</b-nav-item>
          <b-nav-item to="/contacts">Contacts</b-nav-item>
          <b-nav-item to="/organisations">Organisations</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="logoff()" right>Logoff</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <logon></logon>
    <router-view id="router-view"></router-view>
  </div>
</template>

<script>
import Logon from './components/Logon.vue'

export default {
  name: 'App',
  components: {
    Logon,
  },
  computed: {
    need_authentication() {
      return !this.$store.getters.authenticated;
    },
  },
  methods: {
    logoff() {
      this.$store.commit('jwt', null);
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

  .align-right {
    text-align: right;
  }
  #router-view {
    padding-top: 1em;
  }
</style>
