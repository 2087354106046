<template>
    <b-modal
            id="logon-modal"
            v-model="need_authentication"
            title="Logon"
            @ok="logon"
            ok-only no-close-on-backdrop no-close-on-esc>
        <template #modal-header>Logon
        </template>
        <span v-if="error">{{ error }}</span>
        <b-form-input v-model="username" placeholder="username" @keydown.enter.native="enter_username"></b-form-input>
        <b-form-input v-model="password" type="password" placeholder="password"
                      ref="password" @keydown.enter.native="logon">
        </b-form-input>
    </b-modal>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Logon',
    data: function() {
        return {
            username: '',
            password: '',
            error: '',
            refresh_period: 0,
        }
    },
    watch: {
        // call again the method if the route changes
        // '$route': function(to, from) {
        //     console.log(to + ' => ' + from);
        // }
    },
    methods: {
        logon(bvModalEvent) {
            this.error = '';
            bvModalEvent.preventDefault();

            axios.post(this.$store.getters.api_url('auth'), {
                'username': this.username,
                'password': this.password
            }, {
                withCredentials: true,
            })
            .then(response => {
                this.$store.commit('identity', response.data.identity);
                this.$data.refresh_period = response.data.refresh_from + 1;
                this.keep_auth_alive();
            })
            .catch(error => {
                if (error.response && error.response.status==401) {
                    this.error = 'Invalid credentials.';
                } else {
                    this.error = 'Network error.';
                }
            });
        },
        enter_username() {
            this.$refs.password.focus();
        },
        keep_auth_alive() {
            setTimeout(() => {
                if(this.$store.getters.authenticated) {
                  axios.get(this.$store.getters.api_url(['ping']), {
                      headers: this.$store.getters.auth_headers(),
                      withCredentials: true
                  }).then(() => {
                      this.keep_auth_alive();
                  }).catch(error => {
                      console.log(error);
                  });
                }
            }, this.$data.refresh_period*1000);
        }
    },
    computed: {
        need_authentication() {
            return !this.$store.getters.authenticated;
        },
    }
}
</script>

<style scoped></style>
