<template>
    <div id="contacts">
        <b-container v-if="!show_detail" id="contact-overview">
            <b-row>
                <b-col sm="11"><h1>Organisations</h1></b-col>
                <b-col sm="1" class="align-right">
                    <b-button @click="add_organisation" size="sm"><b-icon-plus></b-icon-plus></b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table v-if="!show_detail" sticky-header striped hover :items="organisations"
                             :fields="fields" :busy="is_busy" primary-key="id"
                             @row-clicked="row_clicked"></b-table>
                </b-col>
            </b-row>
        </b-container>
        <b-container v-if="show_detail" id="organisation-details">
            <b-row>
                <b-col sm="11"><h1>Organisation details</h1></b-col>
                <b-col sm="1" class="align-right">
                    <b-button @click="close_detail" size="sm"><b-icon-x></b-icon-x></b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form @submit="submit_detail">
                        <b-form-group label="Name" label-for="name-input" label-cols-sm="4" label-cols-lg="3">
                            <b-form-input id="name-input" v-model="detail_record.name" @update="changed"
                                          debounce="1000" required></b-form-input>
                        </b-form-group>
                        <b-form-group label="Description" label-for="birthdate-input" label-cols-sm="4" label-cols-lg="3">
                            <b-form-textarea id="description-input" v-model="detail_record.description"
                                               debounce="1000" @update="changed"></b-form-textarea>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                <contact-moment v-bind:parent_item="detail_record" parent_endpoint="contacts"></contact-moment>
              </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <roles v-bind:organisation="detail_record"></roles>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <email v-bind:parent_item="detail_record" parent_endpoint="organisations"></email>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <phone-number v-bind:parent_item="detail_record" parent_endpoint="organisations"></phone-number>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <mail-address v-bind:parent_item="detail_record" parent_endpoint="organisations"></mail-address>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from 'axios';
    import Roles from './organisations/Roles.vue';
    import Email from './details/Email.vue';
    import MailAddress from './details/MailAddress.vue';
    import PhoneNumber from './details/PhoneNumber.vue';
    import ContactMoment from './details/ContactMoments.vue';

    export default {
        name: 'Organisations',
        props: {
        },
        components: {
            Roles,
            Email,
            MailAddress,
            PhoneNumber,
            ContactMoment,
        },
        data: function() {
            return {
                organisations: [],
                fields: [
                    { key: 'id', sortable: true },
                    { key: 'name', sortable: true },
                    { key: 'description', sortable: false, formatter: 'summarize_description' },
                ],
                is_busy: false,
                show_detail: false,
                detail_record: null,
            }
        },
        created: function() {
            this.is_busy = true;
            if(this.$store.getters.authenticated) {
                this.load_organisations();
            }
        },
        methods: {
            'load_organisations': function() {
                axios.get(this.$store.getters.api_url('organisations'), {
                    headers: this.$store.getters.auth_headers(),
                    withCredentials: true
                })
                    .then(response => {
                        this.organisations = response.data;
                        this.is_busy = false;
                    }).catch(error => {
                        console.log(error);
                        this.is_busy = false;
                    });
            },
            'summarize_description': function(value) {
                const summary_length = 20;
                const summary = value.replaceAll('\n', ' ').substring(0, summary_length);
                if(value.length>20) {
                    return summary + ' ...';
                }
                return summary;
            },
            'add_organisation': function() {
                this.detail_record = {
                    id: null,
                    name: "",
                    description: "",
                };
                this.show_detail = true;
            },
            'row_clicked': function(record, index) {
                index;
                this.detail_record = record;
                this.show_detail = true;
            },
            'submit_detail': function(event) {
                event.preventDefault();
                this.show_detail = false;
            },
            'changed': function() {
                let verb = axios.put;
                let endpoint = ['organisations', this.detail_record.id];
                if(this.detail_record.id === null) {
                    // new organisation, POST
                    verb = axios.post;
                    endpoint = 'organisations';
                }
                verb(this.$store.getters.api_url(endpoint), this.detail_record, {
                    headers: this.$store.getters.auth_headers(),
                    withCredentials: true
                })
                    .then(response => {
                        this.detail_record = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            'close_detail': function(event) {
                event.preventDefault();
                if(this.detail_record.id !== null && !this.organisations.some(x => x.id == this.detail_record.id)) {
                    this.organisations.push(this.detail_record);
                }
                this.show_detail = false;
            }
        },
        computed: {
            authenticated: function() {
                return this.$store.getters.authenticated;
            }
        },
        watch: {
            authenticated: function(value) {
                if(value) {
                    this.load_organisations();
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
