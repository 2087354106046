<template>
    <b-container id="dashboard">
        <b-row>
            <b-col sm>

            </b-col>
            <b-col sm>col-sm</b-col>
            <b-col sm>col-sm</b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'Dashboard',
        props: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .col {
        border: 1px solid black;
    }
</style>
