<template>
    <div id="contacts">
        <b-container v-if="!show_detail" id="contact-overview">
            <b-row>
                <b-col sm="11"><h1>Contacts</h1></b-col>
                <b-col sm="1" class="align-right">
                    <b-button @click="add_contact" size="sm"><b-icon-plus></b-icon-plus></b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table v-if="!show_detail" sticky-header striped hover :items="contacts"
                             :fields="fields" :busy="is_busy" primary-key="id" ref="contact_overview"
                             @row-clicked="row_clicked"></b-table>
                </b-col>
            </b-row>
        </b-container>
        <b-container v-if="show_detail" id="contact-details">
            <b-row>
                <b-col sm="11"><h1>Contact details</h1></b-col>
                <b-col sm="1" class="align-right">
                    <b-button @click="close_detail" size="sm"><b-icon-x></b-icon-x></b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form @submit="submit_detail">
                        <b-form-group label="Name" label-for="name-input" label-cols-sm="4" label-cols-lg="3">
                            <b-form-input id="name-input" v-model="detail_record.name" @update="changed"
                                          debounce="1000" required></b-form-input>
                        </b-form-group>
                        <b-form-group label="Birth Date" label-for="birthdate-input" label-cols-sm="4" label-cols-lg="3">
                            <b-form-datepicker id="birthdate-input" v-model="detail_record.birth_date"
                                               debounce="1000" @input="changed"></b-form-datepicker>
                        </b-form-group>
                        <b-form-group label="Notes" label-for="notes-input" label-cols-sm="4" label-cols-lg="3">
                            <b-form-textarea id="notes-input" v-model="detail_record.notes"
                                             debounce="1000" @update="changed"></b-form-textarea>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                <contact-moment v-bind:parent_item="detail_record" parent_endpoint="contacts"></contact-moment>
              </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <roles v-bind:contact="detail_record"></roles>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <email v-bind:parent_item="detail_record" parent_endpoint="contacts"></email>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <phone-number v-bind:parent_item="detail_record" parent_endpoint="contacts"></phone-number>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <mail-address v-bind:parent_item="detail_record" parent_endpoint="contacts"></mail-address>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import Roles from './contacts/Roles.vue';
import Email from './details/Email.vue';
import MailAddress from './details/MailAddress.vue';
import PhoneNumber from './details/PhoneNumber.vue';
import ContactMoment from './details/ContactMoments.vue';

export default {
    name: 'Contacts',
    props: {
    },
    components: {
        Roles,
        Email,
        MailAddress,
        PhoneNumber,
        ContactMoment,
    },
    data: function() {
        return {
            contacts: [],
            fields: [
                { key: 'id', sortable: true },
                { key: 'name', sortable: true },
                { key: 'birth_date', sortable: false, formatter: 'format_date' },
            ],
            is_busy: false,
            show_detail: false,
            detail_record: null,
        }
    },
    created: function() {
        this.is_busy = true;
        if(this.$store.getters.authenticated) {
            this.load_contacts();
        }
    },
    methods: {
        'load_contacts': function() {
            axios.get(this.$store.getters.api_url('contacts'), {
                headers: this.$store.getters.auth_headers(),
                withCredentials: true
            })
                .then(response => {
                    this.contacts = response.data;
                    this.is_busy = false;
                }).catch(error => {
                console.log(error);
                this.is_busy = false;
            });
        },
        'format_date': function(value, key, item) {
            key;
            item;
            return value
        },
        'add_contact': function() {
            this.detail_record = {
                id: null,
                name: "",
                birth_date: null,
                notes: ""
            };
            this.show_detail = true;
        },
        'row_clicked': function(record, index) {
            index;
            this.detail_record = record;
            this.show_detail = true;
        },
        'submit_detail': function(event) {
            event.preventDefault();
            this.show_detail = false;
        },
        'changed': function() {
            if(this.detail_record.id === null) {
                // New contact, POST
                axios.post(this.$store.getters.api_url('contacts'), this.detail_record, {
                    headers: this.$store.getters.auth_headers(),
                    withCredentials: true
                })
                    .then(response => {
                        this.detail_record = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                // Existing contact, PUT
                axios.put(this.$store.getters.api_url(['contacts', this.detail_record.id]), this.detail_record, {
                    headers: this.$store.getters.auth_headers(),
                    withCredentials: true
                })
                    .then(response => {
                        this.detail_record = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        'close_detail': function(event) {
            event.preventDefault();
            if(this.detail_record.id !== null && !this.contacts.some(x => x.id == this.detail_record.id)) {
                this.contacts.push(this.detail_record);
            }
            this.show_detail = false;
        },
    },
    computed: {
        authenticated: function() {
            return this.$store.getters.authenticated;
        }
    },
    watch: {
        authenticated: function(value) {
            if(value) {
                this.load_contacts();
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
