import Vue from 'vue'
import Vuex from 'vuex'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Dashboard from './components/Dashboard.vue'
import Contacts from './components/Contacts.vue'
import Organisations from './components/Organisations.vue'

Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueRouter);

Vue.config.productionTip = false;

const store = new Vuex.Store({
  state: {
    user_info: null,
  },
  mutations: {
    identity(state, identity) {
      if(identity !== null) {
          axios.get(this.getters.api_url(['users', identity]), {
            headers: this.getters.auth_headers(),
            withCredentials: true,
          })
              .then(response => {
                this.commit('user_info', response.data);
              });
      } else {
        this.commit('user_info', null);
      }
    },
    user_info(state, data) {
      state.user_info = data;
    }
  },
  getters: {
    authenticated: state => {
      return state.user_info !== null;
    },
    user_id: state => {
      if(state.user_info) {
        return state.user_info.id;
      }
      return null;
    },
    username: state => {
      if(state.user_info) {
        return state.user_info.username;
      }
      return null;
    },
    api_url: () => (endpoint) => {
      if(endpoint.constructor === Array) {
        endpoint = endpoint.join('/');
      }
      const url = new URL(endpoint, process.env.VUE_APP_API_URL);
      return url.href;
    },
    auth_headers: () => () => {
        function get_cookie(name) {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
        }
        return {'X-CSRF-TOKEN': get_cookie('csrf_access_token')}
    },
  }
});

const routes = [
    { path: '/', component: Dashboard },
    { path: '/contacts', component: Contacts },
    { path: '/organisations', component: Organisations },
];

const router = new VueRouter({
    routes
});

new Vue({
  render: h => h(App),
  store: store,
  router
}).$mount('#app');
